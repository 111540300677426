.breadcrumb_section {
    // background-color: gray !important;
    height: 400px;
    position: relative;
    color: #000;

    .breadcrumb-title {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & .heading {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 0px;
            color: #fff;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            word-spacing: 2px;
        }

        ul {
            display: flex;

            p,
            a {
                font-size: 16px;
                letter-spacing: 1px;
                text-align: center;
                color: #ffffff;
                margin-bottom: 0px;
                margin-left: 5px;
            }

            p {
                color: #e41f7a;
            }
        }


    }
}

@media screen and (max-width: 768px) {
    .breadcrumb_section {
    height: 300px;
    position: relative;
    color: #000;

    .breadcrumb-title {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        & .heading {
            font-size: 25px;
        }
    }
 }
}