$body-color: #354b60;
$green-color: #2ecc71;

body {
  background: $body-color;
}

.square {
  width: 8px;
  height: 30px;
  background: $green-color;
  border-radius: 10px;
  display: block;
  animation: turn 2.5s ease infinite;
}

.top {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: rotate(90deg);
}

.bottom {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: rotate(-90deg);
}

.left {
  position: absolute;
  left: 40%;
  top: 50%;
}

.right {
  position: absolute;
  left: 40%;
  top: 50%;
  transform: rotate(-180deg);
}

@keyframes turn {
  0% {
    transform: translateX(0) translateY(0) rotate(0);
  }
  70% {
    transform: translateX(400%) translateY(100%) rotate(90deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(0);
  }
}
