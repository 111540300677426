.navbar-nav {
  > .nav-item {
    > .nav-link {
      color: white !important;
    }

    &.active {
      > .nav-link {
        color: #47b66d !important;
        opacity: 1 !important;
      }
    }
  }
}
.home_header {
  .navbar-nav {
    > .nav-item {
      > .nav-link {
        color: #fff !important;
      }

      &.active {
        > .nav-link {
          color: #47b66d !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

.test {
  padding: 0px 0px !important;
}

header.reverse-scroll.sticky-header.header-appear {
  .navbar-nav {
    > .nav-item {
      > .nav-link {
        color: gray !important;
      }

      &.active {
        > .nav-link {
          color: #47b66d !important;
        }
      }
    }
  }
}

.contact-btn {
  outline: none;
  border: none !important;
  background-color: #2ed47c !important;
  padding: 8px 9px !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  color: white !important;
}

@media screen and (max-width: 1164px) {
  .contact-btn {
    padding: 8px 9px !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1023px) {
  .navbar-nav {
    > .nav-item {
      > .nav-link {
        color: black !important;
      }

      &.active {
        > .nav-link {
          color: #47b66d !important;
          opacity: 1 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 988px) {
  .contact-btn {
    padding: 6px 6px !important;
    text-transform: capitalize !important;
    font-size: 8px !important;
  }
}

@media screen and (max-width: 767px) {
  header.reverse-scroll.sticky-header.header-appear {
    height: unset;
  }
}
